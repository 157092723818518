.induction_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.induction_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;

}

.induction_quicklinks {
    display: grid;
    grid-template-columns: 400px 400px;
    margin: 20px 0px 20px 0px;
}

.induction_quicklinks button {
    font-weight: 600;
    height: 200px;
    width: 380px;
    padding: 10px;
    color: white;
    font-size: 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
}

.induction_quicklinks button:hover {
    background-color:#666666;
    transition: 0.7s;
}

.induction_bodilyfluids {
    margin: 10px;
    background-image: url(../../Assets/bodily.png);
    background-size: cover;

}

.induction_bulkchillers {
    margin: 10px;
    background-image: url(../../Assets/co2.png);
    background-size: cover;
}

.induction_forklift {
    margin: 10px;
    background-image: url(../../Assets/forklift.png);
    background-size: cover;
}

.induction_productspills {
    margin: 10px;
    background-image: url(../../Assets/spills.png);
    background-size: cover;
}

.induction_credits {
    margin: 10px;
    background-image: url(../../Assets/creditsfaded.png);
    background-size: cover;
}

.induction_sharedop {
    margin: 10px;
    background-image: url(../../Assets/sharedop.png);
    background-size: cover;
}

.induction_backbutton button {
    font-weight: 600;
    height: 50px;
    width: 200px;
    padding: 10px;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
    margin: 0px 0px 200px 0px;
}

.induction_backbutton button:hover {
    background-color:#666666;
    transition: 0.7s;
}

.green {
    
    box-shadow: inset 0 0 0 50vw rgba(55, 255, 36, 0.596); 
}

@media all and (max-width: 800px) {
    .induction_header {
        margin: 75px 0px 10px 0px;
        width: 500px;
    
    }
    
    .induction_quicklinks {
        display: grid;
        grid-template-columns: 250px 250px;
        margin: 20px 0px 20px 0px;
    }
    
    .induction_quicklinks button {
        height: 200px;
        width: 230px;
        padding: 10px;
        font-size: 25px;
    }

    .induction_text {
        width: 500px;
    }
}

@media all and (max-width: 500px) {
    .induction_header {
        margin: 75px 0px 10px 0px;
        width: 300px;
    
    }
    
    .induction_quicklinks {
        display: flex;
        flex-direction: column;
        margin: 20px 0px 20px 0px;
    }
    
    .induction_quicklinks button {
        height: 200px;
        width: 300px;
        padding: 10px;
        font-size: 25px;
    }

    .induction_text {
        width: 300px;
    }
}