.contactout_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contactout_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;

}


.contactout_container th, .contactout_container td {
    border: 1px solid;
    border-collapse: collapse;
    background-color: #f0f0f0;
    padding: 10px;
}

.contactout_container table {
    border-collapse: collapse;
}

.contactin_back button {
    font-weight: 600;
    height: 50px;
    width: 200px;
    padding: 10px;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
    margin: 0px 0px 200px 0px;
}

.contact_backin button:hover {
    background-color:#666666;
    transition: 0.7s;
}

.contactin_back {
    margin-top: 20px;
}

.contactin_text {
    width: 780px;
    color: rgb(24, 24, 24);
}

.department {
    margin: 20px;
}

.person {
    margin: 20px;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    visibility: hidden;
    opacity: 0;
  }
  .overlay:target {
    visibility: visible;
    opacity: 1;
  }
  
  .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 780px;
    position: relative;
  }
  
  .popup h2 {
    margin-top: 0;
    color: rgb(22, 22, 22);
  }
  .popup .close {
    position: absolute;
    top: 10px;
    right: 30px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
  }
  .popup .close:hover {
    color: #FFD600;
  }
  .popup .content {
    margin: 0px;
    font-size: 20px;
    max-height: 30%;
    overflow: auto;
  }

  @media all and (max-width: 800px) {
    .contactout_header {
        margin: 75px 0px 10px 0px;
        width: 500px;
        background-color: black;
        color: white;
        border-radius: 10px;
    }

    .contactin_text {
        width: 500px;
        color: rgb(24, 24, 24);
    }

    .contactout_tables {
        width: 500px;
    }

    .popup {
        margin: 70px auto;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        width: 500px;
        position: relative;
      }
  }

  @media all and (max-width: 500px) {
    .contactout_header {
        margin: 75px 0px 10px 0px;
        width: 300px;
        background-color: black;
        color: white;
        border-radius: 10px;
    }

    .contactin_text {
        width: 300px;
        color: rgb(24, 24, 24);
    }
    .popup {
        margin: 70px auto;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        width: 300px;
        position: relative;
      }

      .contactout_container th, .contactout_container td {
        border: 1px solid;
        border-collapse: collapse;
        background-color: #f0f0f0;
        padding: 10px;
    }

    .contactout_tables {
        width: 300px;
    }
  }