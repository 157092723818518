.register_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.register_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.register_form {
    text-align: left;
    margin: 0px 0px 20px 0px;
    padding: 20px 100px;
    background-color: rgb(245, 245, 245);
}

.register_form button {
    font-size: 20px;
    border: none;
    background-color: rgb(10, 10, 10);
    color: white;
    width: 400px;
    margin: 30px 0px;
    padding: 10px 0px;
    font-weight: 600;
    cursor: pointer;
}

.register_form input {
    width: 400px;
}

.register_text {
    color: rgb(26, 26, 26);
}

@media all and (max-width: 800px) {
    .register_header {
        width: 500px;
    }

    .register_text {
        width: 500px;
    }
    
    .register_form {
        text-align: left;
        padding: 20px 50px;
    }
}

@media all and (max-width: 500px) {
    .register_header {
        width: 300px;
    }

    .register_text {
        width: 300px;
    }
    
    .register_form {
        text-align: left;
        padding: 15px 25px;
    }

    .register_form button {
        font-size: 20px;
        color: white;
        width: 245px;
        margin: 30px 0px;
        padding: 10px 0px;
        font-weight: 600;
        cursor: pointer;
    }
    
    .register_form input {
        width: 240px;
    }
}