.assessments_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.assessments_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;

}

.assessments_quicklinks {
    display: grid;
    grid-template-columns: 400px 400px;
    margin: 20px 0px 20px 0px;
}

.assessments_quicklinks button {
    font-weight: 600;
    height: 200px;
    width: 380px;
    padding: 10px;
    color: white;
    font-size: 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
}

.assessments_quicklinks button:hover {
    background-color:#666666;
    transition: 0.7s;
}

.assessments_induction {
    margin: 10px;
    background-image: url(../Assets/induction.png);
    background-size: cover;
}

.assessments_healthsaftey {
    margin: 10px;
    background-image: url(../Assets/healthsaftey.png);
    background-size: cover;
}

@media all and (max-width: 800px) {
    .assessments_header {
        margin: 75px 0px 10px 0px;
        width: 500px;
    }
    
    .assessments_quicklinks {
        display: grid;
        grid-template-columns: 250px 250px;
        margin: 20px 0px 20px 0px;
    }
    
    .assessments_quicklinks button {
        height: 200px;
        width: 230px;
        padding: 10px;
        font-size: 25px;
    }

    .assessments_text {
        width: 500px;
    }
}

@media all and (max-width: 500px) {
    .assessments_header {
        margin: 75px 0px 10px 0px;
        width: 300px;
    }
    
    .assessments_quicklinks {
        display: flex;
        flex-direction: column;
        margin: 20px 0px 20px 0px;
    }
    
    .assessments_quicklinks button {
        height: 200px;
        width: 300px;
        padding: 10px;
        font-size: 25px;
    }

    .assessments_text {
        width: 300px;
    }
}
