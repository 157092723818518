.contactin_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contactin_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;

}

.department th, .department td, .department tr {
    border: 1px solid;
    border-collapse: collapse;
    background-color: #f0f0f0;
    padding: 10px;
}

.person th, .person td {
    border: 1px solid;
    border-collapse: collapse;
    background-color: #f0f0f0;
    padding: 10px;
}

.contactin_tables table {
    border-collapse: collapse;
}

.contactin_tables {
    display: flex;
    flex-direction: row;
}

.contactin_back button {
    font-weight: 600;
    height: 50px;
    width: 200px;
    padding: 10px;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
    margin: 0px 0px 200px 0px;
}

.contact_backin button:hover {
    background-color:#666666;
    transition: 0.7s;
}

.contactin_back {
    margin-top: 20px;
}

.contactintext {
    width: 780px;
    color: rgb(24, 24, 24);
}

.department {
    margin: 20px;
}

.person {
    margin: 20px;
}

@media all and (max-width: 800px) {

    .contactintext {
        width: 500px;
    }

    .contactin_tables {
        display: flex;
        flex-direction: column;
    }

    .contactin_header {
        margin: 75px 0px 10px 0px;
        width: 500px;
    }
}

@media all and (max-width: 500px) {
    .contactintext {
        width: 300px;
    }

    .contactin_tables {
        display: flex;
        flex-direction: column;
    }

    .contactin_header {
        margin: 75px 0px 10px 0px;
        width: 300px;
    }

    .department th, .department td, .department tr {
        border: 1px solid;
        border-collapse: collapse;
        background-color: #f0f0f0;
        padding: 5px;
    }
    
    .person th, .person td {
        border: 1px solid;
        border-collapse: collapse;
        background-color: #f0f0f0;
        padding: 5px;
    }
}