.bodilyfluids_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loader_overlay {
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.548);
}

.loader_overlay_hidden{
    width: 100%;
    display: none;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    height: 100%;
    background-color: rgba(182, 34, 34, 0.548);
}

.bodilyfluids_header {
    margin: 75px 0px 20px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.bodilyfluids_form {
    width: 680px;
    margin: 20px 0px 20px 0px;
    padding: 50px;
    font-size: 20px;
    text-align: left;
    background-color: rgb(230, 230, 230);
}

.bodilyfluids_form button {
    margin: 20px 0px 0px 0px;
    background: black;
    color: white;
    font-size: 20px;
    height: 50px;
    width: 100%;
    cursor: pointer;
}

.pnsformlabel {
    font-weight: 600;
    margin-bottom: 10px;
}

.pnsfrominput {
    display: grid;
    grid-template-columns: 25px 600px;
    row-gap: 15px;
}

.pnsform {
    display: flex;
    flex-direction: column;
}

.bodilyfluids_form td {
    padding: 20px;
}

.bodilyfluids_video-wrapper {
    width: 780px;
    height: 500px;
}

.form_backbutton {
    margin: 20px 0px 300px 0px;
}

.form_back {
    font-weight: 600;
    height: 50px;
    width: 200px;
    padding: 10px;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
    margin: 0px 0px 200px 0px;
}

.form_back:hover {
    background-color:#666666;
    transition: 0.7s;
}

.questionlabel {
    margin-left: 10px;
}

@media all and (max-width: 800px) {
    .bodilyfluids_header {
        width: 500px;
    
    }
    
    .bodilyfluids_form {
        width: 460px;
        margin: 10px 0px 10px 0px;
        padding: 20px;
        font-size: 20px;
    }
    
    .bodilyfluids_form button {
        margin: 20px 0px 20px 0px;
        font-size: 20px;
        height: 50px;
    }
    
    .pnsformlabel {
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    .pnsfrominput {
        display: grid;
        grid-template-columns: 25px 350px;
        row-gap: 15px;
    }
    
    .bodilyfluids_form td {
        padding: 20px;
    }
    
    .bodilyfluids_video-wrapper {
        width: 500px;
        height: 300px;
    }
    
    .form_backbutton {
        margin: 20px 0px 300px 0px;
    }
    
    .questionlabel {
        margin-left: 10px;
    }
}

@media all and (max-width: 500px) {
    .bodilyfluids_header {
        width:290px;
        padding: 10px;
    
    }
    
    .bodilyfluids_form {
        width: 280px;
        margin: 10px 0px 10px 0px;
        padding: 10px;
        font-size: 17px;
    }
    
    .bodilyfluids_form button {
        margin: 20px 0px 20px 0px;
        font-size: 20px;
        height: 50px;
    }
    
    .pnsformlabel {
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    .pnsfrominput {
        display: grid;
        grid-template-columns: 25px 170px;
        row-gap: 15px;
    }
    
    .bodilyfluids_form td {
        padding: 20px;
    }
    
    .bodilyfluids_video-wrapper {
        width: 300px;
        height: 200px;
    }
    
    .form_backbutton {
        margin: 20px 0px 300px 0px;
    }
    
    .questionlabel {
        margin-left: 10px;
    }
}

