.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 400px;
    height: 500px;
    border-radius: 20px;
}

.login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(../../Assets/login.png);
    background-size: cover;
}

.login-content img {
    width: 300px;
    margin: 40px;
}

.form-group {
    font-size: 20px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin: 5px 0px;
}

.form-group input {
    width: 300px;
    height: 30px;
    font-size: 20px;
}

.login-content button {
    font-size: 20px;
    margin: 0px;
    border: none;
    background-color: rgb(10, 10, 10);
    color: white;
    width: 308px;
    padding: 10px 0px;
    font-weight: 600;
    cursor: pointer;
}

@media all and (max-width: 500px) {
    .login-content {
        width: 300px;
    }

    .login-content button {
        width: 258px;
    }
    
    .form-group input {
        width: 250px;
        height: 30px;
        font-size: 20px;
    }

    .login-content img {
        width: 250px;
        margin: 40px 0px 20px 0px;
    }
}

.loader-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(17, 17, 17, 0.5);
    display:flex;
    justify-content: center;
    align-items: center;
  }
  .loader {
    display: inline-block;
    width: 18px;
    height: 18px;
    position: relative;
    background-color: white;

  }

  
  @keyframes loader {
    0% { transform: rotate(0deg);}
    25% { transform: rotate(180deg);}
    50% { transform: rotate(180deg);}
    75% { transform: rotate(360deg);}
    100% { transform: rotate(360deg);}
  }
  
  @keyframes loader-inner {
    0% { height: 0%;}
    25% { height: 0%;}
    50% { height: 100%;}
    75% { height: 100%;}
    100% { height: 0%;}
  }

  .loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 6px solid rgba(255,255,255,.3);
    border-radius: 50%;
    border-top-color: rgb(255, 255, 255);
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }