.databasetable th, .databasetable td {
    border: 1px solid;
    border-collapse: collapse;
    background-color: #f0f0f0;
    padding: 10px;
}

.databasetable table {
    margin: 20px 0px;
    border-collapse: collapse;
}

.database_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.database_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;

}

.searchlabel {
    font-size: 15px;
    margin: 10px;
}

.searchselect {
    font-size: 15px;
    margin: 10px;
}

.searchinput {
    font-size: 15px;
    margin: 10px;
}

.database_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.database_content {
    width: 900px;
    margin-bottom: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.styled-table, .styled-table2 {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    width: 900px;
}

.styled-table3 {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    font-size: 20px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    width: 1100px;
}

.styled-table.test {
    width: 1200px;
}

.column_date {
    width: 150px;
} 

.database_search input {
    border: solid 1px rgb(0,61,28);
    width: 400px;
    border-radius: 30px;
    padding: 15px 0px 15px 40px;
    font-size: 20px;
    margin: 0px;
    font-family: 'Inter', sans-serif;
    color: rgb(141, 141, 141);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 15px 0px rgba(146, 146, 146, 0.2);
}

.styled-table a, .styled-table2 a, .styled-table3 a {
    text-decoration: none;
}

.styled-table th, .styled-table2 th, .styled-table3 th {
    background-color: rgb(20, 20, 20);
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td,.styled-table2 th,
.styled-table2 td {
    padding: 12px 15px;
    text-align: left;
}

.styled-table tr {
    background-color: rgb(245, 245, 245);
}

.styled-table tr,.styled-table td {
    border: 1px solid rgb(172, 172, 172);
}

.styled-table tr:nth-of-type(even),.styled-table2 tr:nth-of-type(even) {
    background-color: #ffffff;
}

.styled-table tr:last-of-type,.styled-table2 tr:last-of-type {
    border-bottom: 2px solid rgb(19, 19, 19);
}


.yes {
    background-color: rgb(140, 255, 24);
}

.maybe {
    background-color: rgb(140, 255, 24);
}

.no {
    background-color: rgb(255, 35, 35);
}

.enabled .progress_status {
    background-color: rgb(140, 255, 24);
}

.disabled .progress_status {
    background-color: rgb(255, 35, 35);
}

.pointer:hover {
    cursor: pointer;
    color: rgb(143, 143, 143);
    transition: 0.3s;
}

.pointer {
    color: black;
    transition: 0.3s;
}

.styled-table button {
    background-color: rgb(19, 19, 19);
    border: none;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    padding: 10px 25px;
    font-size: 20px;
}

.styled-table button:hover {
    background-color: rgb(73, 73, 73);
    transition: 0.3s;
    cursor: pointer;
}

.close {
    background-color: rgb(19, 19, 19);
    border: none;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    padding: 10px 25px;
    font-size: 20px;
}

.close:hover {
    background-color: rgb(73, 73, 73);
    transition: 0.3s;
    cursor: pointer;

}

@media print {
    .styled-table {
        width: 80%;
    }
}