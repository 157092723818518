.healthsafety_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.healthsafety_header {
    margin: 75px 0px 10px 0px;
    width: 780px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.healthsafetysubmit {
    margin: 20px 0px 0px 0px;
    background: black;
    color: white;
    font-size: 20px;
    height: 50px;
    width: 100%;
    cursor: pointer;
}
.healthsafety_form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(224, 224, 224);
    width: 780px;
    margin: 40px;
}

.healthsafety_form {
    margin: 40px;
    width: 700px;
}

.form_table{
    width:100%;
    margin:0;
    text-align: left;
}

.healthsafety_back button {
    font-weight: 600;
    height: 50px;
    width: 200px;
    padding: 10px;
    color: white;
    font-size: 20px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
    margin: 0px 0px 200px 0px;
}

.healthsafety_back button:hover {
    background-color:#666666;
    transition: 0.7s;
}

.button_has {
    font-weight: 600;
    height: 200px;
    width: 780px;
    padding: 10px;
    color: white;
    font-size: 30px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    background-color: rgb(10, 10, 10);
}

.button_has a {
    text-decoration: none;
    color: inherit;
}

.button_has:hover {
    background-color:#666666;
    transition: 0.7s;
}

.button_has {
    background-image: url(../../Assets/healthsaftey.png);
    background-size: cover;
}

@media all and (max-width: 800px) {
    .healthsafety_header {
        margin: 75px 0px 10px 0px;
        width: 500px;
    }

    .healthsafety_form-container {
        width: 500px;
        margin: 40px;
    }
    
    .healthsafety_form {
        width: 450px;
        margin: 40px;
    }
    
    .button_has {
        height: 150px;
        width: 500px;
        padding: 10px;
        font-size: 30px;
    }

    .healthsafety_text {
        width: 500px;
    }
}

@media all and (max-width: 500px) {
    .healthsafety_header {
        margin: 75px 0px 10px 0px;
        width: 260px;
        padding: 0px 20px;
    }

    .healthsafety_form-container {
        width: 300px;
        margin: 40px;
    }
    
    .healthsafety_form {
        width: 250px;
        margin: 40px;
    }
    
    .button_has {
        height: 100px;
        width: 300px;
        padding: 10px;
        font-size: 30px;
    }

    .healthsafety_text {
        width: 300px;
    }
}